// import firebase from 'firebase/app';
// import 'firebase/messaging';

// export let firebaseConfig = {
//   apiKey: 'AIzaSyCin2aByuJ4xl6GvNYdkTQC4AF2swdOhrg',
//   authDomain: 'ens---csb.firebaseapp.com',
//   projectId: 'ens---csb',
//   storageBucket: 'ens---csb.appspot.com',
//   messagingSenderId: '151943788436',
//   appId: '1:151943788436:web:c0e0f9d7b966e97294c181',
//   measurementId: 'G-M98REQ4BH7'
// };

// firebase.initializeApp(firebaseConfig);

// let messaging = null;
// if (firebase.messaging.isSupported()) {
//   messaging = firebase.messaging();
//   messaging.usePublicVapidKey(
//     'BJ4sgXIderlxk0liAbmVb7ahLagbH1nIvj6xrZQTgGeVxE_xVDO0NPq319HWAX4v4AdGtBKlQvhLjuXJZXNB9O0'
//   );
// }

// export { messaging };
