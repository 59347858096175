import parse from 'html-react-parser';

export const APILINK =
  process.env.NODE_ENV === 'production'
    ? 'https://csblombardia.ens.it:4000'
    : process.env.NODE_ENV === 'development'
    ? 'https://ens-backend.meltingbugs.com'
    : 'http://ens-backend.localhost';
export const HOST =
  process.env.NODE_ENV === 'production'
    ? 'csblombardia.ens.it'
    : process.env.NODE_ENV === 'development'
    ? 'peer.meltingbugs.com'
    : 'peer.localhost';
    
export const ICESERVERS = [
  { urls: ['stun:stun.meltingbugs.com:5349', 'stun:stun.solunicanet.it:5349'] },
  {
    urls: [
      'turn:turn.meltingbugs.com:3478?transport=udp',
      'turn:turn.meltingbugs.com:3478?transport=tcp',
      'turns:turn.meltingbugs.com:5349?transport=tcp',
      'turn:turn.solunicanet.it:3478?transport=udp',
      'turn:turn.solunicanet.it:3478?transport=tcp',
      'turns:turn.solunicanet.it:5349?transport=tcp'
    ],
    credential: 'T34mC0mp4ny!!!Turn2021',
    username: 'solunicanet'
  }
];

export const TITLEOPTIONS = [
  { value: 'scuola/universita', label: 'scuola/università' },
  {
    value: 'avvocato/notaio/tribunale',
    label: 'avvocato/notaio/tribunale'
  },
  { value: 'agenzie_turismo/biglietterie', label: 'agenzie turismo/biglietterie' },
  { value: 'sport', label: 'sport' },
  {
    value: 'sanita/ospedali',
    label:
      'sanità/ospedali (SOLO IN PRESENZA no distanza per non sovrapporsi al servizio Lombardia LIS)'
  },
  { value: 'compagnie_telefoniche_(per_info)', label: 'compagnie telefoniche' },
  { value: 'banca/assicurazione', label: 'banca/assicurazione/posta' },
  { value: 'negozi_(contatti_servizi_clienti)', label: 'negozi/ assistenza tecnica' },
  { value: 'sportelli_informativi', label: 'sportelli informativi/patronato/assistenza fiscale' },
  { value: 'veterinario', label: 'veterinario' },
  { value: 'motorizzazione', label: 'motorizzazione' },
  { value: 'colloquio_di_lavoro', label: 'colloquio di lavoro' },
  { value: 'assemblea_condominiale', label: 'assemblea condominiale' },
  { value: "forze_dell'ordine", label: "forze dell'ordine" },
  { value: 'cerimonie_religiose', label: 'cerimonie religiose' },
  { value: 'eventi_istituzionali', label: 'eventi istituzionali (Convegni e tavoli di lavoro)' },
  { value: 'riunioni', label: 'riunioni' },
  { value: 'zoom_link', label: 'riunioni in zoom e altre piattaforme (fornire link)' },
  { value: 'altro', label: 'altro' }
];

export const MAIL = 'csblombardia@ens.it';
export const TITLE = 'Consiglio Regionale della Lombardia ';
export const ADDRESS = 'Via Boscovich 38 – 20124 Milano';
export const TELVIEW = '02 20401836';
export const TELOBJ = '+390220401836';
export const MOBVIEW = '02 20520239';
export const MOBOBJ = '+390220520239';
export const MAILENS = 'lombardia@ens.it';
export const SITEENS = 'https://lombardia.ens.it/';

export const LINKAPPSTORE =
  'https://apps.apple.com/it/app/ens-comunicare-senza-barriere/id1564410520?l=en-GB';
export const LINKPLAYSTORE = 'https://play.google.com/store/apps/details?id=com.solunicanet.ens';
export const TERMSANDCONDITIONP1 = parse(
  `I dati da Lei forniti saranno trattati dall’ENS, titolare del trattamento, anche in forma automatizzata, nel rispetto delle adeguate misure di sicurezza ex art. 32 del Regolamento e non saranno sottoposti a diffusione, comunicazione, né ad altro trasferimento verso soggetti terzi diversi da ENS stesso, ad esclusione di quelli contenuti nell’informativa, nonché dei soggetti istitutzionali. I dati potranno essere conservati anche per fini statistici, attraverso l’anonimizzazione e pseudonimizzazione. Il titolare del trattamento dei dati è ENS Onlus, con sede in Roma – Via Gregorio VII n. 120. L’esercizio dei diritti potrà avvenire in forma scritta mediante l’invio di una email all’indirizzo privacy@ens.it. Responsabile del Trattamento è indicato nel presidente del Consiglio Regionale Lombardia`
);
export const TERMSANDCONDITIONP2 = parse(
  `Io sottoscritto dichiaro di aver letto e compreso l’informativa sul trattamento e la protezione dei dati personali ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 pubblicata dall'ENS sul sito www.ens.it nell’apposita sezione privacy e che L’ENS tratterà i dati solo ai fini dell’erogazione del servizio “Comunicare Senza Barriere”, favorendo  il contatto iniziale con il singolo professionista, manlevando lo stesso ente da qualsivoglia responsabilità in merito alla prestazione resa ed al rapporto con il professionista stesso.`
);

//QUESTA COSTANTE SUPPORTA TUTTO L'HTML E I LINK
export const AVVISOSOSPENSIONEMODAL = parse(
  `Il servizio d'interpretariato è stato sospeso fino a data da definire.<br /> Ci scusiamo per il disagio. <a href="https://www.google.it">LINK</a>`
);
//QUESTA COSTANTE SUPPORTA TUTTO L'HTML E I LINK
export const AVVISOSOSPENSIONECALENDAR = parse(`<div className="avviso_sospensione">
        <p><strong>ATTENZIONE</strong>
          <br />Dal 16 febbraio 2022 l'interpretariato in presenza è stato sospeso fino a data da definire a causa dell'esaurimento delle risorse.
          <br />Continua il servizio di interpretariato a distanza.
          <br /><br /><a href="https://lombardia.ens.it/csblombardia/sospensione-servizio/" style={{color:'black'}} target="_blank" rel="noopener">Scopri di più</a>
        </p>
      </div>`);

export const BLACKBOARD_URL = 'https://csblombardia.ens.it:5858';
